// healthy hearts for dogs

import Bowser from 'bowser';
import moment from "moment";
import './src/styles/global.css';

export async function onRouteUpdate({ location }){
    try {
        const time8601              = await moment().toISOString(true);
        const performanceNow        = await new Date().getTime();
        const performanceDNSLookup  = await (performance.timing.domainLookupEnd - performance.timing.navigationStart)/1000;
        const performanceLoadTime   = await (performanceNow - performance.timing.loadEventEnd)/1000;
        const browserData           = await Bowser.getParser(window.navigator.userAgent).parsedResult;
        const userId                = await window && window.user_id;
        const userIp                = await window && window.user_ip;
        const userName              = await window && window.user_name;

        const data = await JSON.stringify({
            "browser_height"        : Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
            "browser_width"         : Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
            "browser_size"          : Math.max(document.documentElement.clientWidth, window.innerWidth || 0) + "x" + Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
            "browser_name"          : browserData.browser.name,
            "browser_version"       : browserData.browser.version,
            "device_height"         : window.screen.height,
            "device_size"           : window.screen.width + "x" + window.screen.height,
            "device_width"          : window.screen.width,
            "engine_name"           : browserData.engine.name,
            "engine_version"        : browserData.engine.version,
            "http_host"             : window.location.host,
            "http_href"             : window.location.href,
            "http_pathname"         : window.location.pathname,
            "http_protocol"         : window.location.protocol,
            "http_search"           : window.location.search,
            "http_title"            : document.title,
            "okko_app_reference"    : "33101-app",
            "okko_section_name"     : "Healthy Hearts for Dogs",
            "os_name"               : browserData.os.name,
            "os_version"            : browserData.os.version,
            "os_version_name"       : browserData.os.versionName,
            "performance_DNS_Load"  : performanceDNSLookup,
            "performance_Page_Load" : performanceLoadTime,
            "platform_type"         : browserData.platform.type,
            "platform_vendor"       : browserData.platform.vendor,
            "platform_model"        : browserData.platform.model,
            "timestamp"             : Date.now(),
            "timestamp_iso"         : time8601,
            "user_id"               : userId,
            "user_ip"               : userIp,
            "user_name"             : userName,
            // "user_id"           : typeof window !== "undefined" ? window.user_id : "",
            // "user_ip"           : typeof window !== "undefined" ? window.user_ip : "",
            // "user_name"         : typeof window !== "undefined" ? window.user_name : "",
        });

        const requestFunction = async () => {
            try {
                await console.log('OKKO Analytics')
                const request = await fetch('https://webhooks.mongodb-stitch.com/api/client/v2.0/app/okko-analytics-app-crczf/service/testService1/incoming_webhook/webhook_write1?secret=obvEZ7CtM0Oy9X4OsW9GH5uD3GI5ET9ck6jQ', {
                    method: "POST",
                    headers: {
                        "Content-type": "application/javascript",
                    },
                    body: data
                })
                const requestResult = await request.json();
                console.log('OKKO Analytics ID: ', requestResult);
                console.log('OKKO Analytics Data: ', JSON.parse(data));
            } catch(error) {
                console.error('OKKO Analytics Error: ', error);
            }
        }

        // console.log('Would-be-sent data:' , data);
        await requestFunction();
        
    } catch (error) {
        console.log('Error: ', error);
    }
}

export { default as wrapRootElement } from './src/state/ReduxWrapper';
