import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import rootReducer from './store.js';

const store = createStore(
    rootReducer,
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
)

// let unsubscribe = store.subscribe(e=>console.log(e));

export default ({ element }) => {
    // console.log('getting state: ', store.getState());
    return (
    <Provider store={store}>
        {element}
    </Provider>
    )
};
