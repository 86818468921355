import { combineReducers } from 'redux';
import {
    PAGE_INCREMENT,
    NUMBER_INCREASE,
    NUMBER_DECREASE,
    LIST_PUSH,
    LIST_POP,
    HEALTHY_HEARTS_NAVIGATION,
    HEART_DISEASE_NAVIGATION,
} from './actionTypes.js';

console.log('lets see what i imported: ', PAGE_INCREMENT, NUMBER_DECREASE, NUMBER_INCREASE, LIST_PUSH, LIST_POP)

// state
const initialState = {
    navigationCount     : 0,
    someNumber          : 500,
    someList            : ['aa',22,'c5d6','45615'],
    healthyHeartsPage   : 0,
    heartDiseasePage    : 0,
};

// reducers
const pageIncrementReducer = (state = initialState.navigationCount, action) => {
    if( action.type === PAGE_INCREMENT ){
        return state+1;
    } else {
        return state;
    }
};
const numberChangeReducer = (state = initialState.someNumber, action) => {
    switch (action.type) {
        case NUMBER_INCREASE:
            return state+action.numberToAdd;
        case NUMBER_DECREASE:
            return state-action.numberToMinus;
        default:
            return state;
    };
};
const listChangeReducer = (state = initialState.someList, action) => {
    switch (action.type) {
        case LIST_POP:
            let tmp = [...state];
            tmp.pop();
            return tmp;
        case LIST_PUSH:
            return [...state, action.itemToAdd];
        default:
            return state;
    }
}
const healthyHeartsNavigationReducer = (state = initialState.healthyHeartsPage, action) => {
    switch (action.type) {
        case HEALTHY_HEARTS_NAVIGATION:
            return action.healthyHeartsPage;
        default:
            return state;
    }
}
const heartDiseaseNavigationReducer = (state = initialState.heartDiseasePage, action) => {
    switch (action.type) {
        case HEART_DISEASE_NAVIGATION:
            return action.heartDiseasePage;
        default:
            return state;
    }
}

// store
const rootReducer = combineReducers({
    pageIncrementReducer,
    numberChangeReducer,
    listChangeReducer,
    healthyHeartsNavigationReducer,
    heartDiseaseNavigationReducer,
});

export default rootReducer;
